
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";
import GroupItem from "./ConditionGroupItemConfig.vue";
@Options({
    name: "flowable-nodes-condition-node-config",
    components: { GroupItem },
})
export default class ConditionNodeConfig extends NodesConfigMixins {
    private showOrgSelect: boolean;
    private sortOption: any = {
        animation: 300,
        chosenClass: "choose",
        scroll: true,
        sort: true,
    };
    get nowNodeLeave() {
        return this.prioritySortList.indexOf(this.selectedNode);
    }
    //条件节点
    get prioritySortList() {
        let node = this.nodeMap.get(this.selectedNode.parentId);
        //console.log(this.selectedNode.id, node);
        if (node) {
            return node.branchs || [];
        }
        return [];
    }
    public addConditionGroup() {
        this.config.groups.push({
            cids: [],
            groupType: "OR",
            conditions: [],
        });
    }
    get formRequiredList() {
        //构造可用条件选项
        const conditionItems: any = [];
        this.formItems.forEach((item: any) => this.filterFormRequired(item, conditionItems));
        return conditionItems;
    }
    public filterFormRequired(item: any, list: any) {
        if (item.name === "SpanLayout") {
            item.props.items.forEach((sub: any) => this.filterFormRequired(sub, list));
        } else if (item.props.required) {
            list.push({ title: item.title, id: item.id, valueType: item.valueType });
        }
    }
    // public selectUser() {
    //     this.showOrgSelect = true;
    // }
    // public selected(select: any) {
    //     console.log(select);
    //     this.showOrgSelect = false;
    //     select.forEach((val: any) => this.select.push(val));
    // }
    // public removeOrgItem(index: any) {
    //     this.select.splice(index, 1);
    // }
}
